export const SCREEN = Object.freeze({
    XS: 'xs',
    SM: 'sm',
    MD: 'md',
    LG: 'lg',
    XL: 'xl',
});

export const BREAKPOINT = Object.freeze({
    XS: 0,
    SM: 375,
    MD: 768,
    LG: 1024,
    XL: 1440,
});

export const CONTAINER_PADDING = Object.freeze({
    XS: 16,
    SM: 16,
    MD: 16,
    LG: 40,
    XL: 40,
});

export const CONTAINER_SIZE = Object.freeze({
    XS: BREAKPOINT.XS - 2 * CONTAINER_PADDING.XS,
    SM: BREAKPOINT.SM - 2 * CONTAINER_PADDING.SM,
    MD: BREAKPOINT.MD - 2 * CONTAINER_PADDING.MD,
    LG: BREAKPOINT.LG - 2 * CONTAINER_PADDING.LG,
    XL: BREAKPOINT.XL - 2 * CONTAINER_PADDING.XL,
});

export const GRID_COLUMNS = Object.freeze({
    XS: 4,
    SM: 4,
    MD: 8,
    LG: 12,
    XL: 12,
});

export const GRID_GAP = Object.freeze({
    XS: 16,
    SM: 16,
    MD: 32,
    LG: 32,
    XL: 32,
});

/**
 * Note: You probably don't want to use this, but rely on our ui store instead ;)
 * @param {Number} width
 * @returns {String}
 */
export function getScreenForWidth(width) {
    switch (true) {
        case width < BREAKPOINT.SM:
            return SCREEN.XS;
        case width < BREAKPOINT.MD:
            return SCREEN.SM;
        case width < BREAKPOINT.LG:
            return SCREEN.MD;
        case width < BREAKPOINT.XL:
            return SCREEN.LG;
        default:
            return SCREEN.XL;
    }
}

/**
 * Note: You probably don't want to use this, but rely on our ui store instead ;)
 * @returns {Object}
 */
export function getWindowSize() {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
    };
}
