import { isElementHidden, createBaitRequest, isBaitRequestBlocked, createBaitDiv } from '@/utils/adBlockUtils';
import store from '@/store';

export async function domLevelAdBlocksDetection(): Promise<boolean> {
    createBaitDiv();

    return new Promise((resolve) => {
        setTimeout(() => {
            const baitAds = document.getElementById('baitAds') as HTMLElement | null;
            resolve(baitAds?.offsetHeight === 0 || isElementHidden(baitAds));

            if (baitAds) {
                baitAds.remove();
            }
        }, 100);
    });
}

/**
 * Detect if any known ad blocker mechanism is detected
 * @return Promise
 */
export async function detectAllAdblocker() {
    try {
        const domBlocked = await domLevelAdBlocksDetection();
        // then check request adblockers (using only one request for all)
        const xhttp = await createBaitRequest();
        if (domBlocked || isBaitRequestBlocked(xhttp)) {
            store.dispatch('ad/updateUserHasAdBlocker', true);
        }
    } catch (error) {
        store.dispatch('ad/updateUserHasAdBlocker', false);
    }
}
