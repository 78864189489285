import { reactive } from 'vue';
import { AuthType } from '@components/AuthForm/AuthType';
import { prefersReducedMotion } from '@helpers/browser';
import { hasAcceptCookie, acceptCookies } from '@/plugins/cookie';
import route from '@router/route';
import { getLocale } from '@/plugins/i18n';
import { trackStickyElement } from '@/modules/sticky';
import { SCREEN } from '@/utils/uiUtils';
import { useScreenSize } from '@/composables/screenSize';
import { useWindowSize } from '@/composables/windowSize';
import { getLanguageCodeFromLocale } from '@/utils/langUtils';

const types = {
    SET_ACCEPT_COOKIE: 'SET_ACCEPT_COOKIE',
    ENABLE_FULLSCREEN: 'ENABLE_FULLSCREEN',
    ENABLE_STICKY_TOPBAR: 'ENABLE_STICKY_TOPBAR',
    SET_LOCALE: 'SET_LOCALE',
    SET_STICKY_TOPBAR: 'SET_STICKY_TOPBAR',
    SET_INSET_TOPBAR: 'SET_INSET_TOPBAR',
    SET_MAIN_MENU_DRAWER_OPEN: 'SET_MAIN_MENU_DRAWER_OPEN',
    SET_NOTIFICATIONS_DRAWER_OPEN: 'SET_NOTIFICATIONS_DRAWER_OPEN',
    SET_SCROLLING_ENABLED: 'SET_SCROLLING_ENABLED',
    DISABLE_SEARCH_TOP_BAR: 'DISABLE_SEARCH_TOP_BAR',
    SET_ACTIVE_AUTH_OVERLAY: 'SET_ACTIVE_AUTH_OVERLAY',
    DISABLE_FOOTER: 'DISABLE_FOOTER',
    DISABLE_SIGN_BUTTONS: 'DISABLE_SIGN_BUTTONS',
    ENABLE_SIGN_BUTTONS: 'ENABLE_SIGN_BUTTONS',
    TOGGLE_GLOBAL_LISTENERS: 'TOGGLE_GLOBAL_LISTENERS',
    WINDOW_RELOAD: 'WINDOW_RELOAD',
    SET_PAGE_BOTTOM_OFFSET: 'SET_PAGE_BOTTOM_OFFSET',
    SET_PAGE_TOP_OFFSET: 'SET_PAGE_TOP_OFFSET',
    SET_TOP_BAR_HEIGHT: 'SET_TOP_BAR_HEIGHT',
    SET_PREFERS_REDUCED_MOTION: 'SET_PREFERS_REDUCED_MOTION',
    SET_PAGE_IS_VISIBLE: 'SET_PAGE_IS_VISIBLE',
    SET_PAGE_IS_FOCUSED: 'SET_PAGE_IS_FOCUSED',
};

const getImageFallback = () => {
    let { lang } = document.documentElement;
    if (lang === 'en-GB') {
        lang = 'en';
    }
    return `/images/placeholders/no_file_preview_${lang}.svg`;
};

const getModule = () => {
    switch (route().current()) {
        case 'newsfeed.show':
            return 'newsfeed';
        case 'course.show':
            return 'course';
        case 'document.show':
            return 'document';
        case 'groups.show':
            return 'group';
        case 'courses.posts.question':
        case 'documents.posts.question':
        case 'groups.posts.question':
            return 'postDetail';
        default:
            return route().current();
    }
};

const beforeUnloadListener = (event) => {
    event.preventDefault();
    // eslint-disable-next-line no-param-reassign
    event.returnValue = '';
    return '';
};

const breakpoints = reactive(useScreenSize());
const windowSize = reactive(useWindowSize());

const state = {
    locale: getLocale(), // @deprecated please use Pinia store.
    isMainMenuDrawerOpen: false,
    isNotificationsDrawerOpen: false,
    topBarIsSticky: false,
    topBarIsAlwaysSticky: false,
    topBarIsInset: false,
    scrollingIsEnabled: true,
    acceptedCookiesUsage: hasAcceptCookie(),
    fullscreenEnabled: false,
    imageFallback: getImageFallback(),
    searchTopBarIsEnable: true,
    activeAuthOverlay: null,
    footerIsEnabled: true,
    signButtonsAreEnabled: true,
    globalListenersEnabled: true,
    windowReload: true,
    module: getModule(),
    topBarHeight: 0,
    // aditional offset per page that blocks the viewport due to fixed elements overflowing the content
    pageTopOffset: 0,
    pageBottomOffset: 0,
    // this is toggled via a browser setting and should be checked to not display additional animations
    prefersReducedMotion: prefersReducedMotion(),
    pageIsVisible: document.visibilityState === 'visible',
    pageIsFocused: document.hasFocus(),
};

const mutations = {
    [types.SET_PREFERS_REDUCED_MOTION](state, bool) {
        state.prefersReducedMotion = bool;
    },
    [types.ENABLE_STICKY_TOPBAR](state, payload) {
        state.topBarIsAlwaysSticky = payload;
    },
    [types.ENABLE_FULLSCREEN](state) {
        state.fullscreenEnabled = true;
    },
    [types.SET_LOCALE](state, payload) {
        state.locale = payload.locale;
    },
    [types.SET_STICKY_TOPBAR](state, payload) {
        state.topBarIsSticky = payload;
    },
    [types.SET_MAIN_MENU_DRAWER_OPEN](state, val) {
        state.isMainMenuDrawerOpen = val;
    },
    [types.SET_NOTIFICATIONS_DRAWER_OPEN](state, val) {
        state.isNotificationsDrawerOpen = val;
    },
    [types.SET_ACCEPT_COOKIE](state) {
        state.acceptedCookiesUsage = true;
    },
    [types.SET_SCROLLING_ENABLED](state, payload) {
        document.body.classList.toggle('overflow-hidden', !payload);
        state.scrollingIsEnabled = payload;
    },
    [types.DISABLE_SEARCH_TOP_BAR](state, payload) {
        state.searchTopBarIsEnable = payload;
    },
    [types.SET_ACTIVE_AUTH_OVERLAY](state, payload) {
        state.activeAuthOverlay = payload;
    },
    [types.SET_INSET_TOPBAR](state) {
        state.topBarIsInset = true;
    },
    [types.DISABLE_FOOTER](state) {
        state.footerIsEnabled = false;
    },
    [types.DISABLE_SIGN_BUTTONS](state) {
        state.signButtonsAreEnabled = false;
    },
    [types.ENABLE_SIGN_BUTTONS](state) {
        state.signButtonsAreEnabled = true;
    },
    [types.TOGGLE_GLOBAL_LISTENERS](state) {
        state.globalListenersEnabled = !state.globalListenersEnabled;
    },
    [types.WINDOW_RELOAD](state, payload) {
        if (state.windowReload === payload) return;

        if (payload) {
            window.removeEventListener('beforeunload', beforeUnloadListener, { capture: true });
        } else {
            window.addEventListener('beforeunload', beforeUnloadListener, { capture: true });
        }

        state.windowReload = payload;
    },
    [types.SET_TOP_BAR_HEIGHT](state, height) {
        state.topBarHeight = height;
    },

    [types.SET_PAGE_TOP_OFFSET](state, payload) {
        state.pageTopOffset = payload;
    },

    [types.SET_PAGE_BOTTOM_OFFSET](state, payload) {
        state.pageBottomOffset = payload;
    },
    [types.SET_PAGE_IS_VISIBLE](state, payload) {
        state.pageIsVisible = payload;
    },
    [types.SET_PAGE_IS_FOCUSED](state, payload) {
        state.pageIsFocused = payload;
    },
};

const actions = {
    setPageTopOffset({ commit }, value) {
        commit(types.SET_PAGE_TOP_OFFSET, value);
    },
    resetPageTopOffset({ commit }) {
        commit(types.SET_PAGE_TOP_OFFSET, 0);
    },
    setPageBottomOffset({ commit }, payload) {
        commit(types.SET_PAGE_BOTTOM_OFFSET, payload);
    },
    enableFullscreen({ commit }) {
        commit(types.ENABLE_FULLSCREEN);
    },
    setMainMenuDrawerOpen({ commit }, val) {
        if (val) {
            // close notifications drawer to only have one open at a time
            commit(types.SET_NOTIFICATIONS_DRAWER_OPEN, false);
        }
        commit(types.SET_MAIN_MENU_DRAWER_OPEN, val);
    },
    setNotificationsDrawerOpen({ commit }, val) {
        if (val) {
            // close main menu drawer to only have one open at a time
            commit(types.SET_MAIN_MENU_DRAWER_OPEN, false);
        }
        commit(types.SET_NOTIFICATIONS_DRAWER_OPEN, val);
    },

    trackStickyTopBar(store, payload) {
        trackStickyElement(store, {
            ...payload,
            mutationName: types.SET_STICKY_TOPBAR,
        });
    },
    setScrollingEnabled({ commit }, payload) {
        commit(types.SET_SCROLLING_ENABLED, payload);
    },
    enableInsetTopbar({ commit }) {
        commit(types.SET_INSET_TOPBAR);
    },
    openRegistration({ dispatch, commit }, extras = {}) {
        dispatch(
            'auth/setAuthDetails',
            {
                extras,
            },
            { root: true },
        ).then(() => {
            commit(types.SET_ACTIVE_AUTH_OVERLAY, AuthType.REGISTER);
        });
    },
    openLogin({ dispatch, commit }, extras = {}) {
        dispatch(
            'auth/setAuthDetails',
            {
                extras,
            },
            { root: true },
        ).then(() => {
            commit(types.SET_ACTIVE_AUTH_OVERLAY, AuthType.LOGIN);
        });
    },
    closeAuthOverlay({ commit }) {
        commit(types.SET_ACTIVE_AUTH_OVERLAY, null);
    },
    acceptCookies,
    enableSearchTopBar({ commit }, payload) {
        commit(types.DISABLE_SEARCH_TOP_BAR, payload);
    },
    disableFooter({ commit }) {
        commit(types.DISABLE_FOOTER);
    },
    enableSignButtons({ commit }) {
        commit(types.ENABLE_SIGN_BUTTONS);
    },
    disableSignButtons({ commit }) {
        commit(types.DISABLE_SIGN_BUTTONS);
    },
    toggleGlobalListeners({ commit }) {
        commit(types.TOGGLE_GLOBAL_LISTENERS);
    },
    toggleWindowReload({ commit }, payload) {
        commit(types.WINDOW_RELOAD, payload);
    },
};

const getters = {
    getLocale: (state) => state.locale, // @deprecated please use pinia for this instead / access the state directly
    /**
     * provides an object of locales and bools to indicate which locale is currently active like:
     * {
     *   DE: false,
     *   EN: true,
     *   EN_GB: false,
     * }
     */
    languageCode: (state) => getLanguageCodeFromLocale(state.locale), // @deprecated please use pinia for this instead
    isMainMenuDrawerOpen: (state) => state.isMainMenuDrawerOpen,
    isNotificationsDrawerOpen: (state) => state.isNotificationsDrawerOpen,
    /** @DEPRECATED - rely on the more powerful screen getter instead */
    screenSize: () => {
        switch (breakpoints.current) {
            case SCREEN.XS:
            case SCREEN.SM:
                return 'small';
            case SCREEN.MD:
                return 'medium';
            case SCREEN.LG:
            case SCREEN.XL:
            default:
                return 'large';
        }
    },
    screen: () => breakpoints,
    topBarIsSticky: (state) => state.topBarIsSticky,
    topBarAlwaysSticky: (state) => state.topBarIsAlwaysSticky,
    topBarIsInset: (state) => state.topBarIsInset,
    topBarHeight: (state) => state.topBarHeight,
    /* defines the offset to the top viewport till page content will be visible. Useful for scrolling to elements */
    pageScrollTopOffset: (state) => state.topBarHeight + state.pageTopOffset,
    pageBottomOffset: (state) => state.pageBottomOffset,
    acceptedCookiesUsage: (state) => state.acceptedCookiesUsage,
    scrollingIsEnabled: (state) => state.scrollingIsEnabled,
    fullscreenEnabled: (state) => state.fullscreenEnabled,
    imageFallback: (state) => state.imageFallback,
    searchTopBarIsEnable: (state) => state.searchTopBarIsEnable,
    activeAuthOverlay: (state) => state.activeAuthOverlay,
    pageGroup: () => document.getElementsByTagName('body')[0].getAttribute('data-page-group'),
    footerIsEnabled: (state) => state.footerIsEnabled,
    signButtonsAreEnabled: (state) => state.signButtonsAreEnabled,
    globalListenersEnabled: (state) => state.globalListenersEnabled,
    windowReload: (state) => state.windowReload,
    module: (state) => state.module,
    prefersReducedMotion: (state) => state.prefersReducedMotion,
    pageIsVisible: (state) => state.pageIsVisible,
    pageIsFocused: (state) => state.pageIsFocused,
    windowSize: () => windowSize,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
